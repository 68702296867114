import { checkIfItIsClientOrSchool } from '../../../utils';
import { __ } from '../../../i18n';
import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

export function getProfiles(organization) {
  const organizationType = organization?.type;
  const notVisible = isNetwork(organization);

  return [
    {
      value: 'STUDENT',
      notVisible,
      text:
        checkIfItIsClientOrSchool(organizationType) === 1
          ? __('Student')
          : __('Client'),
    },
    { value: 'STAFF', text: __('Staff') },
    { value: 'ADMIN', text: __('Admin') },
  ];
}
