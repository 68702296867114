import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Loader, Segment, Image, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import ListView from '../../components/ListView';
import Page from '../../components/Page';
import Modal from '../../components/ui/Modal';
import Button from '../../components/ui/Button';
import ChatNews from '../../app/Presentation/ChatNews/ViewUI'
import { shouldShowChatNewsModal } from '../../app/Presentation/ChatNews/ViewModel';
import { hasCommunicationStatusFeedback } from '../../app/Domain/UseCases/Unleash/validations/HasCommunicationStatusFeedback';

import MessageItem from '../Message/Item';
import MessageFilter from '../Message/Filter';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 25;

const organizationEntitySearchQuery = gql`
  query OrganizationEntitySearchQuery($id: ID!, $search: String, $limit: Int) {
  node (id: $id) {
    ... on Organization{
      id: dbId
      entities(search: $search, limit: $limit) {
        nodes {
          id: dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
        }
      }
    }
  }
}`;

@inject('store')
@graphql(gql`query OrganizationMessagesQuery (
  $id: ID!,
  $limit: Int,
  $offset: Int,
  $search: String,
  $attachments: [MessageAttachment]
  $recipientIds: [Int],
  $labelIds: [ID],
  $entityId: ID!,
) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        messages(limit: $limit, offset: $offset, search: $search, attachments: $attachments, recipientIds: $recipientIds, labelIds: $labelIds) {
          nodes {
            id: dbId
            statusSend(entityId: $entityId)
            ... MessageItemOrganization
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
        labels(limit: 100) {
          nodes {
            id: dbId
            title
            color
          }
        }
      }
    }
  }
  ${MessageItem.fragments.organizationMessage}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    pollInterval: 3 * 60 * 1000,
    variables: {
      id: ownProps.params.organization_id,
      limit: PAGE_SIZE,
      offset: 0,
      search: ownProps.location.query.search || '',
      attachments: (ownProps.location.query.attachments && JSON.parse(ownProps.location.query.attachments)) || [],
      recipientIds: (ownProps.location.query.recipientIds && JSON.parse(ownProps.location.query.recipientIds)) || null,
      labelIds: (ownProps.location.query.labelIds && JSON.parse(ownProps.location.query.labelIds)) || [],
      entityId: ownProps.store.currentEntity.id
    }
  })
})
@observer
export default class OrganizationMessages extends Page {
  state = {
    loading: false,
    margin: false
  }

  componentDidMount() {
    try {
      if (hj && this.props.store.currentOrganization.plan === 'FREE') {
        // eslint-disable-next-line no-undef
        hj('trigger', 'mensagensfree');
      }
    } catch (e) {
      console.error(e);
    }
    const shouldShow = shouldShowChatNewsModal(this.props.store);
    this.setState({ shouldShowConversationModal: shouldShow });
  }

  renderRow = (message, key) => {
    return (
      <MessageItem
        key={message.id || key}
        search={this.props.location.query.search}
        message={filter(MessageItem.fragments.organizationMessage, message)}
        to={`/organizations/${this.props.params.organization_id}/messages/${message.id}`}
        organization
        hasCommunicationStatusFeedback={hasCommunicationStatusFeedback(
          this.props.params.organization_id,
        )}
        statusSend={message.statusSend}
      />
    );
  };

  renderInfosModal = () => this.props.store.appends.push(
    <Modal
      id="InfoModal"
      onClose={() => this.props.store.appends.pop()}
      closeOnBack
      size="large"
      style={{ width: 903, marginLeft: -451 }}
      header={
        <span>{__('Learn to Send Messages')}<Icon name="envelope" style={{ fontWeight: 300, marginLeft: 12 }} /></span>
      }
      content={
        <div>
          <div style={{ marginBottom: 20 }}>
            <p>{__('Watch the video and discover everything you can do in the Messages menu.')}</p>
            <p>
              {__('If you prefer, open it on Youtube to continue watching it while using it. ')}
              <a href="https://youtu.be/rsSc5DgSm-s" target="_blank" rel="noopener noreferrer" style={{ color: '#094fff' }}>
                {__('Youtube')}
                <Icon name="square arrow up right" />
              </a>
            </p>
          </div>
          <iframe
            style={{ borderRadius: 12 }}
            width="855"
            height="481"
            src="https://www.youtube.com/embed/rsSc5DgSm-s"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            allowFullScreen
          />
        </div>
      }
    />
  );

  render() {
    const { params, data, location, store } = this.props;

    if ((data.loading && !data.node) || !data.node || this.state.loading) return <Loader active inline="centered" />;

    const messages = data.node.messages;
    const { nodes, pageInfo } = messages;

    const isFreeOrganization = store.currentOrganization && store.currentOrganization.plan === 'FREE';
    /* if (this.state.shouldShowConversationModal) {
      return (
        <ChatNews
        router={this.props.router}
        store={this.props.store}
        onClose={()=> this.setState({shouldShowConversationModal: false})} />
      )
    } */
    return (
      <div id="OrganizationMessages">
        <div style={{ maxWidth: '70rem', margin: 'auto' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: this.isMobile() ? 0 : 40,
              alignItems: 'center'
            }}
          >
            {!this.isMobile() && <h2 style={{ paddingLeft: 8, fontSize: '2.29rem', lineHeight: '38px', paddingBottom: '3px' }}>{__('Communications')}</h2>}
            {
              !this.isMobile() && isFreeOrganization &&
              <div
                style={{ cursor: 'pointer', marginLeft: '11px', display: 'flex' }}
                onClick={() => this.renderInfosModal()}
              >
                <Icon style={{ fontWeight: 300, color: 'rgba(0, 0, 0, 0.24)', fontSize: '20px' }} name="play circle" />
                <span style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.4)' }}>{__('See how to use')}</span>
              </div>
            }
          </div>
          <MessageFilter
            location={this.props.location}
            request={organizationEntitySearchQuery}
            id={params.organization_id}
            onOpenFilters={() => this.setState({ margin: true })}
            onCloseFilters={() => this.setState({ margin: false })}
            resultExtract={{ edgeName: 'entities' }}
            style={!this.isMobile ? { display: 'grid', marginBottom: 24 } : {}}
            organization={{
              labels: data.node.labels
            }}
          />
          {
            nodes.length ?
              <ListView
                renderRow={this.renderRow}
                source={nodes}
                pagination={{
                  graphql: true,
                  hasNextPage: pageInfo && pageInfo.hasNextPage,
                  loading: data.loading
                }}
                onLoadMore={() => this.loadMore('messages')}
                segment={false}
                celled={false}
                style={{ margin: 0, marginBottom: this.state.margin ? '20rem' : 0, backgroundColor: 'white' }}
              />
              :
              Object.keys(location.query).length ?
                <Segment basic textAlign="center">
                  <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-30.png')} centered />
                  <div style={{ color: '#9696a0' }}>{__('No messages found!')}</div>
                </Segment>
                :
                <Segment basic textAlign="center">
                  <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-23.png')} centered />
                  <div style={{ color: '#9696a0' }}>{__('You have no new messages, enjoy your day!')}</div>
                </Segment>
          }
          {this.props.children && React.cloneElement(this.props.children,
            {
              super: {
                setLoading: value => this.setState({ loading: value }),
                refetch: this.props.data.refetch
              }
            }
          )}
        </div>
      </div>

    );
  }
}
