import { __ } from '../../../i18n';
import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';

export function simpleFiltersList(organization) {
  const notVisible = isNetwork(organization);

  return [
    {
      name: __('Sort'),
      key: 'order',
      removeButton: false,
      icon: 'arrow down arrow up',
    },
    {
      name: __('Profile'),
      key: 'profiles',
      removeButton: true,
      icon: 'chevron down',
    },
    {
      name: __('Group'),
      key: 'groups',
      removeButton: true,
      multipleTags: true,
      searchable: true,
      style: { width: 105 },
      icon: 'chevron down',
      notVisible,
    },
    {
      name: __('Status'),
      key: 'status',
      removeButton: true,
      icon: 'chevron down',
    },
  ];
}
