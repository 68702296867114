import React from 'react';
import { inject, observer } from 'mobx-react';
import { Divider, Loader, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import TopCover from '../../components/TopCover';
import OrganizationHeader from '../Organization/Header';

import { __ } from '../../i18n';
import FeaturesModal from '../../components/FeaturesModal';
import Container from '../../components/Container';
import Footer from '../../components/Footer';

import '../../assets/css/MainLayout.module.scss';
import { getOrganizationMenuIcons } from './helpers/getOrganizationMenu';
import { organizationPages } from './helpers/organizationPage';

@inject('store')
@graphql(
  gql`
    query OrganizationNodeIdQuery($id: ID!) {
      node(id: $id) @connection(key: "Organization", filter: ["id"]) {
        ... on Organization {
          id: dbId
          color
          live
          link
          type
          username
          fullname
          confMessage
          confMessageStudent
          confMessageStaff
          confMedia
          confCommitment
          confSurvey
          confCharge
          confReport
          confRecurrence
          confForm
          confReplyStudent
          confMediaStudent
          confTerms
          confPresence
          confAdminList
          confRecurrence
          unleashStatus {
            approve_message
            approve_reply
            feature_arquivos
            web_menu_conversations
            show_banner_menu_conversations
            menu_conversations_organization_direct_list
            network_communication
            show_banner_network_communication
            web_conversations_automatic_messages
            new_student_flow
            custom_media_button
            custom_media
            communication_status_feedback
            new_student_flow
            recipients_list_screen
          }
          featureToggles {
            web_menu_conversations_notification_bell
          }
          organizationBanners {
            nodes {
              id
              bannerType
            }
          }
          created
          plan
          premiumPeriod
          isDemonstration
          monthlyMessagesCount
          monthlyMessageQuota
          usage
          features {
            messages
            messageManager
            readingControl
            files
            surveys
            commitments
            moments
            reports
            videos
            audios
            charges
            forms
            otherFiles
            dashboard
            api
            support
            labels
            deleteMessages
            editMessages
            links
            videoConference
            arrived {
              completedTimeout
            }
          }
          network {
            id
          }
          tags {
            nodes {
              id: dbId
              name
            }
          }
          permissions {
            entityScopes
            hasAdmin
          }
          arrivedConfiguration {
            id: dbId
          }
          productFeatures {
            totalCount
            nodes {
              id
              identifier
              plan
              paid
              live
              isEnabled(organizationId: $id)
            }
          }
          getLaunchedProductFeature {
            totalCount
            nodes {
              id
              identifier
              plan
              paid
              live
              isEnabled(organizationId: $id)
            }
          }
          paymentMethods {
            name
          }
          ...TopCover
          ...OrganizationHeader
        }
      }
    }
    ${OrganizationHeader.fragments.organization}
    ${TopCover.fragments.organization}
  `,
  {
    options: (ownProps) => ({
      variables: {
        id: ownProps.params.organization_id,
      },
    }),
    skip: (ownProps) => !ownProps.params.organization_id,
  },
)
@graphql(
  gql`
    query OrganizationNodeUsernameQuery($username: String!) {
      organization(username: $username) {
        id: dbId
        color
        live
        link
        type
        username
        fullname
        timezone
        confMessage
        confMessageStudent
        confMessageStaff
        confMedia
        confCommitment
        confSurvey
        confCharge
        confReport
        confForm
        confReplyStudent
        confMediaStudent
        confTerms
        confPresence
        confAdminList
        confRecurrence
        entryMethod
        created
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        unleashStatus {
          approve_message
          approve_reply
          web_menu_conversations
          show_banner_menu_conversations
          menu_conversations_organization_direct_list
          network_communication
          show_banner_network_communication
          web_conversations_automatic_messages
          new_student_flow
        }
        features {
          messages
          messageManager
          readingControl
          files
          surveys
          commitments
          moments
          reports
          videos
          audios
          charges
          forms
          dashboard
          api
          support
          labels
          deleteMessages
          editMessages
          videoConference
        }
        featureToggles {
          web_menu_conversations_notification_bell
        }
        network {
          id
        }
        tags {
          nodes {
            id: dbId
            name
          }
        }
        permissions {
          entityScopes
          hasAdmin
        }
        productFeatures {
          totalCount
          nodes {
            id
            identifier
            plan
            paid
            live
            # isEnabled(organizationUsername: $username)
          }
        }
        getLaunchedProductFeature {
          totalCount
          nodes {
            id
            identifier
            plan
            paid
            live
            # isEnabled(organizationUsername: $username)
          }
        }
        paymentMethods {
          name
        }
        ...TopCover
        ...OrganizationHeader
      }
    }
    ${OrganizationHeader.fragments.organization}
    ${TopCover.fragments.organization}
  `,
  {
    options: (ownProps) => ({
      variables: {
        username: ownProps.params.username,
      },
      errorPolicy: 'ignore',
    }),
    skip: (ownProps) => !ownProps.params.username,
  },
)
@observer
export default class OrganizationNode extends React.Component {
  componentDidMount() {
    const { data, store } = this.props;
    const organization = data.node || data.organization;
    store.currentOrganization = organization;
    window.addEventListener('hashchange', () => store.appends.pop());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, store, params, location, router } = nextProps;

    if (
      ((data.loading && (!data.node || data.organization)) ||
        (!data.node && !data.organization)) &&
      !data.error
    )
      return;

    if (data.error && data.error.message === 'GraphQL error: Node not found') {
      // eslint-disable-next-line no-undef
      browserHistory.push(
        `/${params.organization_id || params.username}/notfound`,
      );
      return;
    } else if (data.error) return;

    const organization = data.node || data.organization;

    // Search for admins in organization
    let myEntity =
      store.currentUser.entities &&
      store.currentUser.entities.find(
        (e) => e.type === 'ADMIN' && e.organization.id == organization.id,
      );

    // Search for staffs with scopes in organization
    if (!myEntity) {
      myEntity =
        store.currentUser.entities &&
        store.currentUser.entities.find(
          (e) =>
            e.type === 'STAFF' &&
            e.organization.permissions.entityScopes.length &&
            e.organization.id == organization.id,
        );
    }

    // Search for admins in another organization or with no organization
    if (!myEntity) {
      myEntity =
        store.currentUser.entities &&
        store.currentUser.entities.find((e) => e.type === 'ADMIN');
    }

    if (myEntity) store.currentEntity = myEntity;

    store.app.title = organization.fullname;
    store.currentOrganization = organization;

    const { hasAdmin, entityScopes } = store.currentOrganization.permissions;

    // Redirect admins if current page is for a feature that the organization's plan didn't includes
    const currentPage = location.pathname.split('/');

    let planFeatures = organization.features;
    const productFeatures = organization.productFeatures.nodes;

    if (currentPage[3]) {
      let isFeatureEnabled;

      switch (currentPage[3]) {
        case 'reports':
        case 'forms':
          isFeatureEnabled = planFeatures[currentPage[3]];
          break;
        case 'messages':
          isFeatureEnabled =
            planFeatures.messageManager !== 'BASIC' ||
            store.currentUser.isMaster;
          break;
        case 'integrations':
          isFeatureEnabled = planFeatures.api;
          break;
        case 'accesses':
          isFeatureEnabled = planFeatures.links;
          break;
        case 'arrived':
          isFeatureEnabled = productFeatures.some(
            (p) => p.identifier === 'arrived',
            // eslint-disable-next-line prettier/prettier
        );
          break;
        default:
          // eslint-disable-next-line indent
          isFeatureEnabled = true;
      }

      if (!isFeatureEnabled) router.push('/organizations/' + currentPage[2]);
    }

    if (hasAdmin || store.currentUser.isMaster) return;

    // Redirect staffs with scopes to the allowed pages
    let allowedPages = [];

    entityScopes.forEach((scope) => {
      if (Object.keys(organizationPages(organization)).includes(scope)) {
        allowedPages = allowedPages.concat(
          organizationPages(organization)[scope],
        );
      }
    });

    // User has access to the current page
    if (allowedPages.includes(currentPage[3])) return;

    // User has access to any page from the organization menu
    if (allowedPages.length) {
      if (currentPage[3] === 'enrollment') return;
      router.push(currentPage.slice(0, 3).concat(allowedPages[0]).join('/'));
      return;
    }

    // User can't access any page from the organization menu
    router.push('/');
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.currentOrganization = null;
    // store.currentEntity = null;
    window.removeEventListener('hashchange', () => store.appends.pop());
  }

  openPaywall(feature) {
    this.props.store.appends.push(<FeaturesModal feature={feature} />);
  }

  render() {
    const { data, location, store, router, params } = this.props;
    const { unleash } = store;

    if (!data.loading && params.username && !data.organization) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 96,
          }}
        >
          <Container textAlign="center">
            <Message size="large" compact negative>
              <Message.Header>
                {__('Error (404) - Organization Not Found')}
              </Message.Header>
              <p>
                {__(
                  'The requested organization %s was not found.',
                  this.props.params.username.replace('/notfound', ''),
                )}
              </p>
            </Message>
            <Divider section hidden />
            <Footer />
          </Container>
        </div>
      );
    }
    if (
      (data.loading && (!data.node || data.organization)) ||
      (!data.node && !data.organization) ||
      !store.currentOrganization
    )
      return <Loader active />;
    if (!this.props.store.currentEntity)
      return <Loader active inline="centered" />;

    let columnWidth = 13;
    const currentLocation =
      location.pathname.match(/[a-z]+$/) &&
      location.pathname.match(/[a-z]+$/)[0];
    if (
      [
        'groups',
        'messages',
        'reports',
        'moments',
        'forms',
        'accesses',
      ].includes(currentLocation) ||
      location.pathname.indexOf('/reports/') > -1 ||
      location.pathname.indexOf('/forms/') > -1 ||
      (location.pathname.indexOf('/moments') > -1 &&
        currentLocation === 'delete')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      columnWidth = 10;
    }

    const organization = data.node || data.organization;

    const { features, permissions, confTerms, productFeatures, confCharge } =
      organization;

    const { items, mobileItems } = getOrganizationMenuIcons({
      store,
      permissions,
      features,
      productFeatures,
      confTerms,
      confCharge,
      organization: organization,
      unleash,
      router: this.props.router,
      location: this.props.location,
    });

    return (
      <Container
        desktopItems={items}
        mobileItems={mobileItems}
        router={router}
        id="OrganizationNodeMenu"
      >
        {this.props.children}
      </Container>
    );
  }
}
