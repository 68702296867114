/**
 * Params of checkCanShowBanner
 * @param {Object} organization
 * @param {Object} entity
 * @param {Boolean} isOverdue
 * @param {Boolean} showBanner
 * @return {Boolean}
 */

export function checkCanShowBanner({
  organization,
  entity,
  isOverdue,
  showBanner,
}) {
  const productFeatures = organization?.productFeatures?.nodes.filter(
    (pf) => pf.identifier === 'attendanceChannel',
  );
  const hasAttendanceChannel = productFeatures?.length > 0;
  const hasMenuConversation =
    organization?.unleashStatus?.web_menu_conversations &&
    organization?.unleashStatus?.show_banner_menu_conversations;

  const canShowBanner =
    entity &&
    entity.type === 'ADMIN' &&
    hasMenuConversation &&
    !hasAttendanceChannel;
  !isOverdue && showBanner;

  return canShowBanner;
}
