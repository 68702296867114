import React from 'react';
import { __ } from '../../../i18n';
import { organizationShouldSeeConversation } from '../../../helpers/organizationShouldSeeConversation';
import { formatUnleashStatusIntoToggle } from '../../../helpers/formatUnleashStatusIntoToggles';
import { hasConversationProductFeature } from '../../../helpers/hasConversationProductFeature';
import { isNetwork } from '../../../app/Domain/UseCases/Unleash/validations/IsNetwork';
import * as utils from '../../../utils';
import SideMenuMobile from '../../../components/SideMenuMobile';
import FeaturesModal from '../../../components/FeaturesModal';

/**
 * @typedef {{
 * store: any;
 * type: 'ADMIN'|'STAFF'|'STUDENT';
 * permissions: {
 * hasAdmin: boolean;
 * entityScopes: any;
 * };
 * features: {
 * moments: any;
 * reports: any;
 * api: any;
 * forms: any;
 * links: any;
 * messageManager: any;
 * nodes: any;
 * };
 * productFeatures: {
 * nodes: any;
 * };
 * confTerms: any;
 * organization: any;
 * router: any;
 * location: any;
 * }} IGetOrganizationMenu
 */

/**
 * @param {IGetOrganizationMenu} params
 */

export function getOrganizationMenuIcons({
  store,
  permissions,
  features,
  productFeatures,
  confTerms,
  organization,
  unleash,
  location,
  router,
}) {
  const hasSeenArrivedIntroPage = organization.arrivedConfiguration !== null;

  let items = [];
  let mobileItems = [];

  const hasMenuConversations = organizationShouldSeeConversation({
    currentOrganization: store.currentOrganization,
    unleash: {
      toggles: formatUnleashStatusIntoToggle(organization.unleashStatus),
    },
    currentEntity: store.currentEntity,
    isMaster: store?.currentUser?.isMaster,
  });

  const hasMenuArchive =
    organization?.unleashStatus?.feature_arquivos ||
    unleash.unleash.toggles.find(
      (toggle) => toggle.name === 'feature_arquivos',
    );

  const organizationIsNetwork = isNetwork(organization);

  if (
    store?.currentUser?.isMaster ||
    (!organizationIsNetwork &&
      (permissions.hasAdmin ||
        permissions.entityScopes.includes('READ_DASHBOARD')))
  )
    items.push({
      id: 'dashboard',
      icon: 'tachometer alt',
      name: __('Dashboard'),
      to: `/organizations/${organization.id}/dashboard?type=student`,
      toParams: ['type=student'],
      'data-action': 'dashboard',
    });

  if (
    permissions.hasAdmin ||
    permissions.entityScopes.includes('READ_ENTITY') ||
    permissions.entityScopes.includes('READ_GROUP') ||
    store.currentUser.isMaster
  ) {
    items.push({
      id: 'people',
      icon: 'users',
      name: __('People'),
      to: `/organizations/${organization.id}/entities`,
      'data-action': 'entities',
    });
    mobileItems.push({
      id: 'people',
      icon: 'users',
      name: __('People'),
      to: `/organizations/${organization.id}/entities`,
      default: `/organizations/${organization.id}`,
      'data-action': 'entities',
    });
  }

  if (
    store?.currentUser?.isMaster ||
    (!organizationIsNetwork &&
      (permissions.hasAdmin ||
        (permissions.entityScopes.includes('READ_ENTITY') &&
          permissions.entityScopes.includes('READ_GROUP'))))
  ) {
    items.push({
      id: 'channels',
      icon: 'message smile',
      name: __('Channels'),
      to: `/organizations/${organization.id}/channels`,
      'data-action': 'channels',
    });
    mobileItems.push({
      id: 'channels',
      icon: 'message smile',
      name: __('Channels'),
      to: `/organizations/${organization.id}/channels`,
      'data-action': 'channels',
    });
  }

  if (
    permissions.hasAdmin ||
    permissions.entityScopes.includes('READ_GROUP') ||
    store.currentUser.isMaster
  ) {
    items.push({
      id: 'groups',
      icon: 'graduation cap',
      name: __('Groups'),
      to: `/organizations/${organization.id}/groups`,
      'data-action': 'groups',
    });
  }

  if (
    (features?.messageManager !== 'BASIC' &&
      (permissions.hasAdmin ||
        permissions.entityScopes.includes('READ_MESSAGE'))) ||
    store.currentUser.isMaster
  ) {
    items.push({
      id: 'messages',
      icon: 'envelope',
      name: __('Communications'),
      to: `/organizations/${organization.id}/messages`,
      'data-action': 'messages',
    });
    mobileItems.push({
      id: 'messages',
      icon: 'envelope',
      name: __('Communications'),
      to: `/organizations/${organization.id}/messages`,
      'data-action': 'messages',
    });
  } else if (features?.messageManager !== 'ADVANCED') {
    items.push({
      id: 'messages',
      icon: 'envelope',
      name: __('Communications'),
      to: `/organizations/${organization.id}/messages`,
      openPaywall: () => {
        store.appends.push(<FeaturesModal feature={'MESSAGE_MANAGER'} />);
      },
      'data-action': 'messages',
      rightIcon: 'stars',
    });
    mobileItems.push({
      id: 'messages',
      icon: 'envelope',
      name: __('Communications'),
      to: `/organizations/${organization.id}/messages`,
      'data-action': 'messages',
      openPaywall: () => {
        store.appends.push(<FeaturesModal feature={'MESSAGE_MANAGER'} />);
      },
    });
  }

  if (
    hasMenuConversations &&
    hasConversationProductFeature(organization) &&
    !organizationIsNetwork
  ) {
    mobileItems.push({
      icon: 'message',
      name: __('Chats'),
      quantity: store.currentEntity.hasUnreadDirectMessages,
      to: `/organizations/${organization.id}/chats/manager/channels`,
    });
    items.push({
      icon: 'message',
      name: __('Chats'),
      to: `/organizations/${organization.id}/chats/manager/channels`,
    });
  }

  if (
    store?.currentUser?.isMaster ||
    (!organizationIsNetwork &&
      features?.moments &&
      (permissions.hasAdmin ||
        permissions.entityScopes.includes('READ_MESSAGE')))
  ) {
    items.push({
      id: 'moments',
      icon: 'heart',
      name: __('Moments'),
      to: `/organizations/${organization.id}/moments`,
      'data-action': 'moments',
    });
    mobileItems.push({
      id: 'moments',
      icon: 'heart',
      name: __('Moments'),
      to: `/organizations/${organization.id}/moments`,
      'data-action': 'moments',
    });
  }

  if (
    store?.currentUser?.isMaster ||
    (!organizationIsNetwork &&
      features?.reports &&
      (permissions.hasAdmin ||
        permissions.entityScopes.includes('READ_REPORT')))
  ) {
    items.push({
      id: 'reports',
      icon: 'list layout',
      name: __('Reports'),
      to: `/organizations/${organization.id}/reports`,
      'data-action': 'reports',
    });
  } else if (!features?.reports) {
    items.push({
      id: 'reports',
      icon: 'list layout',
      name: __('Reports'),
      to: `/organizations/${organization.id}/reports`,
      openPaywall: () => {
        store.appends.push(<FeaturesModal feature={'REPORTS'} />);
      },
      'data-action': 'reports',
      rightIcon: 'stars',
    });
  }

  if (
    store?.currentUser?.isMaster ||
    (!organizationIsNetwork && organization.confCharge)
  ) {
    const chargeItem = {
      icon: 'donate',
      name: __('ClassPay'),
      'data-action': 'charges',
    };

    if (
      !utils.isEnumIncluded(confTerms, 'PAYMENT') &&
      (store.currentUser.isMaster ||
        (!organizationIsNetwork &&
          (permissions.hasAdmin ||
            permissions.entityScopes.includes('READ_PAYMENT') ||
            permissions.entityScopes.includes('READ_ACCOUNT'))))
    ) {
      items.push({
        id: 'paymentTerms',
        ...chargeItem,
        to: `/organizations/${organization.id}/paymentTerms`,
        new: true,
      });
      // mobileItems.push({ icon: 'donate', name: __('Payments'), to: `/organizations/${organization.id}/paymentTerms`, 'data-action': 'charges', new: true });
    } else if (
      store.currentUser.isMaster ||
      (!organizationIsNetwork &&
        (permissions.hasAdmin ||
          permissions.entityScopes.includes('READ_PAYMENT')))
    ) {
      items.push({
        id: 'payments',
        ...chargeItem,
        to: `/organizations/${organization.id}/payments`,
        new: true,
      });
      // mobileItems.push({ icon: 'donate', name: __('Payments'), to: `/organizations/${organization.id}/payments`, 'data-action': 'charges', new: true });
    } else if (permissions.entityScopes.includes('READ_ACCOUNT')) {
      items.push({
        id: 'accounts',
        ...chargeItem,
        to: `/organizations/${organization.id}/accounts`,
        new: true,
      });
      // mobileItems.push({ icon: 'donate', name: __('Accounts'), to: `/organizations/${organization.id}/accounts`, 'data-action': 'charges', new: true });
    }
  }

  if (
    store.currentUser.isMaster ||
    (!organizationIsNetwork &&
      features?.forms &&
      (permissions.hasAdmin || permissions.entityScopes.includes('READ_FORM')))
  ) {
    items.push({
      id: 'forms',
      icon: 'poll h',
      name: __('Forms'),
      to: `/organizations/${organization.id}/forms`,
      'data-action': 'forms',
    });
  } else if (!features?.forms) {
    items.push({
      id: 'forms',
      icon: 'poll h',
      name: __('Forms'),
      to: `/organizations/${organization.id}/forms`,
      openPaywall: () => {
        store.appends.push(<FeaturesModal feature={'FORMS'} />);
      },
      'data-action': 'forms',
      rightIcon: 'stars',
    });
  }

  if (
    store.currentUser.isMaster ||
    (!organizationIsNetwork &&
      features?.api &&
      (permissions.hasAdmin ||
        permissions.entityScopes.includes('READ_INTEGRATION')))
  ) {
    items.push({
      id: 'integrations',
      icon: 'puzzle piece',
      name: __('Integrations'),
      to: `/organizations/${organization.id}/integrations`,
      'data-action': 'integrations',
    });
  } else if (!features?.api) {
    items.push({
      id: 'integrations',
      icon: 'puzzle piece',
      name: __('Integrations'),
      to: `/organizations/${organization.id}/integrations`,
      openPaywall: () => {
        store.appends.push(<FeaturesModal feature={'INTEGRATION'} />);
      },
      'data-action': 'integrations',
      rightIcon: 'stars',
    });
  }

  if (
    store.currentUser.isMaster ||
    (!organizationIsNetwork &&
      hasMenuArchive &&
      (permissions.hasAdmin || permissions.entityScopes.includes('READ_FILES')))
  ) {
    items.push({
      id: 'files',
      icon: 'archive',
      name: __('Arquivos'),
      to: `/organizations/${organization.id}/files`,
      'data-action': 'files',
    });
  }

  if (
    store.currentUser.isMaster ||
    (!organizationIsNetwork &&
      features?.links &&
      (permissions.hasAdmin || permissions.entityScopes.includes('READ_LINK')))
  ) {
    items.push({
      id: 'accesses',
      icon: 'box open full',
      name: __('Accesses'),
      to: `/organizations/${organization.id}/accesses`,
      'data-action': 'accesses',
    });
  } else if (!features?.links && !organizationIsNetwork) {
    items.push({
      id: 'accesses',
      icon: 'box open full',
      name: __('Accesses'),
      to: `/organizations/${organization.id}/accesses`,
      openPaywall: () => {
        store.appends.push(<FeaturesModal feature={'ACCESSES'} />);
      },
      'data-action': 'accesses',
      rightIcon: 'stars',
    });
  }

  if (
    permissions.hasAdmin ||
    permissions.entityScopes.includes('UPDATE_ORGANIZATION') ||
    store.currentUser.isMaster
  ) {
    items.push({
      id: 'edit',
      icon: 'cog',
      name: __('Settings (menu)'),
      to: `/organizations/${organization.id}/edit`,
      'data-action': 'settings',
    });
  }

  if (
    store.currentUser.isMaster ||
    (!organizationIsNetwork &&
      (permissions.hasAdmin ||
        permissions.entityScopes.includes('UPDATE_ORGANIZATION')))
  ) {
    items.push({
      id: 'enrollment',
      icon: 'gift',
      name: __('Enrollment'),
      'data-action': 'enrollment',
      to: `/organizations/${organization.id}/enrollment`,
    });
  }

  // eslint-disable-next-line sonarjs/no-collapsible-if
  if (
    store.currentUser.isMaster ||
    (!organizationIsNetwork && permissions.hasAdmin)
  ) {
    if (productFeatures.nodes.some((p) => p.identifier === 'arrived')) {
      if (hasSeenArrivedIntroPage) {
        items.push({
          id: 'arrived',
          icon: 'car',
          name: __('Arrived'),
          to: `/organizations/${organization.id}/arrived/history`,
          'data-action': 'arrived',
        });
      } else {
        items.push({
          id: 'arrived',
          icon: 'car',
          name: __('Arrived'),
          to: `/organizations/${organization.id}/arrived/intro`,
          'data-action': 'arrived',
        });
      }
    }
  }

  mobileItems.push({
    id: 'more-actions',
    icon: 'ellipsis stroke vertical',
    name: __('More actions'),
    onClick: () => {
      router.push(location.pathname + location.search + '#side-menu-mobile');
      store.appends.push(<SideMenuMobile router={router} items={items} />);
    },
    'data-action': 'more-actions',
  });

  return { items, mobileItems };
}
