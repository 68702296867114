import { checkCanShowBanner } from '../checkShowBanner';

describe('checkShowBanner', () => {
  it('should check if it has menu conversation', () => {
    const organization = {
      unleashStatus: {
        web_menu_conversations: true,
        show_banner_menu_conversations: true,
      },
    };
    const entity = {
      type: 'ADMIN',
    };
    const isOverdue = false;
    const showBanner = true;
    const canShowBanner = checkCanShowBanner({
      organization,
      entity,
      isOverdue,
      showBanner,
    });
    expect(canShowBanner).toEqual(true);
  });
});
