import { panes } from '../getPanesOptions';

describe('getPanesOptions', () => {
  it('should return the panes options', () => {
    const options = panes();
    expect(options).toEqual([
      { menuItem: 'Revenue', value: 'payments' },
      { menuItem: 'Charges', value: 'charges' },
      { menuItem: 'Accounts and Withdrawals', value: 'accounts' },
    ]);
  });
});
