import { getProfiles } from '../getProfiles';

describe('getProfiles', () => {
  it('should return profiles for a network', () => {
    const organization = {
      type: 'NETWORK',
    };
    const profiles = getProfiles(organization);
    expect(profiles).toEqual([
      { value: 'STUDENT', notVisible: false, text: 'Client' },
      { value: 'STAFF', text: 'Staff' },
      { value: 'ADMIN', text: 'Admin' },
    ]);
  });

  it('should not return student if it is a network', () => {
    const organization = {
      type: 'NETWORK',
    };
    const profiles = getProfiles(organization);
    expect(profiles).not.toEqual([
      { value: 'STUDENT', notVisible: false, text: 'Student' },
    ]);
  });

  it('should return profiles for a school', () => {
    const organization = {
      type: 'SCHOOL',
    };
    const profiles = getProfiles(organization);
    expect(profiles).toEqual([
      { value: 'STUDENT', notVisible: false, text: 'Student' },
      { value: 'STAFF', text: 'Staff' },
      { value: 'ADMIN', text: 'Admin' },
    ]);
  });

  it('should return profiles for a client', () => {
    const organization = {
      type: 'CLIENT',
    };
    const profiles = getProfiles(organization);
    expect(profiles).toEqual([
      { value: 'STUDENT', notVisible: false, text: 'Client' },
      { value: 'STAFF', text: 'Staff' },
      { value: 'ADMIN', text: 'Admin' },
    ]);
  });

  it('should not return empty data', () => {
    const organization = {};
    const profiles = getProfiles(organization);
    expect(profiles).not.toEqual([]);
  });
});
