import { simpleFiltersList } from '../simpleFiltersHelpers';

describe('simpleFiltersHelpers', () => {
  it('should not return group filter if it is a network', () => {
    const organizationFilters = {
      type: 'NETWORK',
    };
    const filters = simpleFiltersList(organizationFilters);
    expect(filters).not.toEqual([
      {
        name: 'Group',
        key: 'groups',
        removeButton: true,
        multipleTags: true,
        searchable: true,
        style: { width: 105 },
        icon: 'chevron down',
        notVisible: false,
      },
    ]);
  });

  it('should return filters for a school', () => {
    const organizationFilters = {
      type: 'SCHOOL',
    };
    const filters = simpleFiltersList(organizationFilters);
    expect(filters).toEqual([
      {
        name: 'Sort',
        key: 'order',
        removeButton: false,
        icon: 'arrow down arrow up',
      },
      {
        name: 'Profile',
        key: 'profiles',
        removeButton: true,
        icon: 'chevron down',
      },
      {
        name: 'Group',
        key: 'groups',
        removeButton: true,
        multipleTags: true,
        searchable: true,
        style: { width: 105 },
        icon: 'chevron down',
        notVisible: false,
      },
      {
        name: 'Status',
        key: 'status',
        removeButton: true,
        icon: 'chevron down',
      },
    ]);
  });

  it('should not return empty filters', () => {
    const organizationFilters = {
      type: 'SCHOOL',
    };
    const filters = simpleFiltersList(organizationFilters);
    expect(filters).not.toEqual([]);
  }, 1000);

  it('should not return empty filters', () => {
    const organizationFilters = {
      type: 'NETWORK',
    };
    const filters = simpleFiltersList(organizationFilters);
    expect(filters).not.toEqual([]);
  }, 1000);

  it('should not return none filters', () => {
    const organizationFilters = {};
    const filters = simpleFiltersList(organizationFilters);
    expect(filters).not.toEqual([]);
  });
});
